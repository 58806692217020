import styles from './style.module.scss'
import React, { ClipboardEventHandler, useRef } from 'react'
import { CreateStoreTypes } from 'components/CreateElement/store/types'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import {
  COMPLIANCE_TEXT,
  NON_REQUIRED_COMPLIANCE_TEXT,
} from 'src/static/constants'
import { placeCaretAtEnd } from 'src/util/functions'
import { observer } from 'mobx-react-lite'

export const ComplianceContent = observer(
  ({ store }: { store: CreateStoreTypes }) => {
    const ref = useRef<HTMLSpanElement>(null)
    const [editableText, setEditableText] = React.useState<string>(
      store.textareaStore.nonRequiredComplianceText
    )

    const onPaste: ClipboardEventHandler<HTMLDivElement> = (e) => {
      e.preventDefault()

      // get text representation of clipboard
      const text = e.clipboardData?.getData('text/plain')

      // insert text manually
      document.execCommand('insertHTML', false, text)
    }

    const handleChangeCompliance: React.FormEventHandler<HTMLDivElement> = (
      e
    ) => {
      store.textareaStore.setNoneRequiredComplianceText(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-expect-error
        e.target.innerText || ''
      )
    }

    const onRevertToDefault: React.MouseEventHandler = (e) => {
      e.stopPropagation()
      setEditableText(NON_REQUIRED_COMPLIANCE_TEXT)
      store.textareaStore.setNoneRequiredComplianceText(
        NON_REQUIRED_COMPLIANCE_TEXT
      )
      if (ref.current) {
        ref.current.innerHTML = NON_REQUIRED_COMPLIANCE_TEXT
        ref.current.innerText = NON_REQUIRED_COMPLIANCE_TEXT
        placeCaretAtEnd(ref.current)
      }
    }

    return (
      <div className={styles.wrap}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className={styles.wrapEditContent}
          onClick={() => ref.current?.focus()}
        >
          <span
            contentEditable={false}
            className={styles.required}
            data-required={true}
          >
            {store.textareaStore.requiredComplianceText}
          </span>
          <span
            ref={ref}
            contentEditable={true}
            onInput={handleChangeCompliance}
            className={styles.editContent}
            onPaste={onPaste}
          >
            {editableText}
          </span>
        </div>
        {store.textareaStore.complianceText !== COMPLIANCE_TEXT && (
          <div>
            <MuiTooltip
              title={'Revert to default message'}
              placement={'top-end'}
              arrow
            >
              <div>
                <Button
                  typeBtn={BtnType.tertiaryIcon}
                  size={BtnSize.small}
                  victorIcon={'undo'}
                  onClick={onRevertToDefault}
                />
              </div>
            </MuiTooltip>
          </div>
        )}
      </div>
    )
  }
)
