import { TableStore } from 'components/NewTable/store/TableStore'
import { makeAutoObservable } from 'mobx'
import { memberColumns } from 'components/NewTable/columns/memberColumns'
import { IMember } from 'src/types/IMember'
import { IOrganizationDetails } from 'src/types/IOrganizationDetails'
import {
  AdminGetOrganizationMembersQuery,
  Direction,
  OrganizationDetails,
} from 'src/generated/graphql'
import { Currencies, numberString } from 'src/util/functions'

export class OrganizationPageStore {
  tableStore: TableStore<IMember>
  constructor() {
    makeAutoObservable(this)
    this.tableStore = new TableStore({
      columns: memberColumns,
      tableName: 'OrganizationPageTable',
      orderBy: 'roleSortPriority',
      order: Direction.Asc,
    })
  }

  organization: IOrganizationDetails | null = null
  membersMap: Map<number, IMember> = new Map()

  get members() {
    return Array.from(this.membersMap.values())
  }

  setOrganization(organization: OrganizationDetails) {
    this.organization = { id: organization.id, ...organization }
  }
  updateOrganization(organization: Partial<OrganizationDetails>) {
    if (!this.organization) {
      return
    }
    this.organization = {
      ...this.organization,
      ...organization,
    }
  }
  setMembersData(data: AdminGetOrganizationMembersQuery) {
    if (data.adminGetOrganizationMembers) {
      this.membersMap.clear()
      this.tableStore.setTotal(data.adminGetOrganizationMembers.total)
      data.adminGetOrganizationMembers.content?.forEach((member) => {
        if (member) {
          this.membersMap.set(member.id, { id: member.id, ...member })
        }
      })
    }
  }

  get autoRechargeString() {
    if (!this.organization?.billingInfo?.autoRecharge) {
      return ''
    }
    const bellow =
      Number(
        this.organization.billingInfo?.autoRechargeThreshold?.replace(
          'Credits',
          ''
        )
      ) || 0
    const bellowString = numberString({
      val: bellow,
    })
    const addCredits =
      this.organization.billingInfo.autoRechargeCreditsAmount || 0
    const addCreditsString = numberString({
      val: addCredits,
    })

    const additionalCreditPrice =
      this.organization.subscription?.price?.additionalCreditPrice || 0

    const costString = numberString({
      val: addCredits * additionalCreditPrice,
      currency: Currencies.usd,
      maximumFractionDigits: 2,
    })
    return `Below: ${bellowString} → Add: ${addCreditsString} / ${costString}`
  }

  get activeSubscriberString() {
    const info: string[] = []
    if (this.organization?.activeSubscriberInfo?.years) {
      info.push(`${this.organization?.activeSubscriberInfo?.years} years`)
    }
    if (this.organization?.activeSubscriberInfo?.months) {
      info.push(`${this.organization?.activeSubscriberInfo?.months} months`)
    }
    if (this.organization?.activeSubscriberInfo?.days) {
      info.push(`${this.organization?.activeSubscriberInfo?.days} days`)
    }
    return info.join(', ')
  }

  get mrrString() {
    if (!this.organization?.mrr) {
      return ''
    }
    return numberString({
      val: this.organization?.mrr,
      currency: Currencies.usd,
    })
  }
  get subscriptionAmountString() {
    if (!this.organization?.subscription?.price?.priceValue) {
      return ''
    }
    return numberString({
      val: this.organization?.subscription?.price?.priceValue,
      currency: Currencies.usd,
    })
  }
  get totalSpendString() {
    if (!this.organization?.totalSpend) {
      return ''
    }
    return numberString({
      val: this.organization?.totalSpend,
      currency: Currencies.usd,
    })
  }
}
