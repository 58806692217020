// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dBuJVxrXR_0rtkqbyr4w{cursor:pointer;border-radius:8px;height:36px;padding-inline:12px;display:flex;justify-content:space-between;align-items:center}.dBuJVxrXR_0rtkqbyr4w .hxtCKV9_uOBui_2QLRTQ{display:flex;align-items:center;gap:8px}.dBuJVxrXR_0rtkqbyr4w:hover{background:var(--primary-color-gray-4)}`, "",{"version":3,"sources":["webpack://./src/components/Input/InputCollapsableCheckbox/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,iBAAA,CACA,WAAA,CACA,mBAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,4CACE,YAAA,CACA,kBAAA,CACA,OAAA,CAEF,4BACE,sCAAA","sourcesContent":[".wrap{\n  cursor: pointer;\n  border-radius: 8px;\n  height: 36px;\n  padding-inline: 12px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  .left{\n    display: flex;\n    align-items: center;\n    gap: 8px;\n  }\n  &:hover{\n    background: var(--primary-color-gray-4);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `dBuJVxrXR_0rtkqbyr4w`,
	"left": `hxtCKV9_uOBui_2QLRTQ`
};
export default ___CSS_LOADER_EXPORT___;
