import classNames from 'classnames'
import styles from './styles.module.scss'
import { ColorsValues } from 'styles/variables'

import { ReactComponent as Ai } from 'icons/defaults/Ai.svg'
import { ReactComponent as AngleDown } from 'icons/defaults/Angle-Down.svg'
import { ReactComponent as AngleLeft } from 'icons/defaults/Angle-Left.svg'
import { ReactComponent as AngleUp } from 'icons/defaults/Angle-Up.svg'
import { ReactComponent as AngleRight } from 'icons/defaults/Angle-Right.svg'
import { ReactComponent as Code } from 'icons/defaults/Code.svg'
import { ReactComponent as Delete } from 'icons/defaults/Delete.svg'
import { ReactComponent as Question } from 'icons/defaults/Question.svg'
import { ReactComponent as Broadcasts } from 'icons/defaults/Broadcasts.svg'
import { ReactComponent as Campaigns } from 'icons/defaults/Campaigns.svg'
import { ReactComponent as Check } from 'icons/defaults/Check.svg'
import { ReactComponent as Triggers } from 'icons/defaults/Triggers.svg'
import { ReactComponent as Plus } from 'icons/defaults/Plus.svg'
import { ReactComponent as Text } from 'icons/defaults/Text.svg'
import { ReactComponent as Number } from 'icons/defaults/Number.svg'
import { ReactComponent as DateTime } from 'icons/defaults/DateTime.svg'
import { ReactComponent as Calendar } from 'icons/defaults/Calendar.svg'
import { ReactComponent as Link } from 'icons/defaults/Link.svg'
import { ReactComponent as ContactsFill } from 'icons/defaults/ContactsFill.svg'
import { ReactComponent as User } from 'icons/defaults/User.svg'
import { ReactComponent as UserSwittch } from 'icons/defaults/UserSwitch.svg'
import { ReactComponent as File } from 'icons/defaults/File.svg'
import { ReactComponent as TextToSpeech } from 'icons/defaults/Text to speech.svg'
import { ReactComponent as Recording } from 'icons/defaults/Recording.svg'
import { ReactComponent as Segments } from 'icons/defaults/Segments.svg'
import { ReactComponent as Play } from 'icons/defaults/Play.svg'
import { ReactComponent as Pause } from 'icons/defaults/Pause.svg'
import { ReactComponent as Warning } from 'icons/defaults/Warning.svg'
import { ReactComponent as Mailchimp } from 'icons/defaults/Mailchimp.svg'
import { ReactComponent as Typeform } from 'icons/defaults/Typeform.svg'
import { ReactComponent as AWeber } from 'icons/defaults/AWeber.svg'
import { ReactComponent as Drip } from 'icons/defaults/Drip.svg'
import { ReactComponent as Unbounce } from 'icons/defaults/Unbounce.svg'
import { ReactComponent as Undo } from 'icons/defaults/Undo.svg'
import { ReactComponent as GravityForms } from 'icons/defaults/Gravity Forms.svg'
import { ReactComponent as Stripe } from 'icons/defaults/Stripe.svg'
import { ReactComponent as Leadpages } from 'icons/defaults/Leadpages.svg'
import { CSSProperties, useState } from 'react'

const icons = {
  ai: <Ai />,
  angleDown: <AngleDown />,
  angleLeft: <AngleLeft />,
  angleRight: <AngleRight />,
  angleUp: <AngleUp />,
  code: <Code />,
  delete: <Delete />,
  question: <Question />,
  broadcasts: <Broadcasts />,
  campaigns: <Campaigns />,
  check: <Check />,
  triggers: <Triggers />,
  plus: <Plus />,
  text: <Text />,
  number: <Number />,
  dateTime: <DateTime />,
  calendar: <Calendar />,
  link: <Link />,
  contactsFill: <ContactsFill />,
  user: <User />,
  userSwitch: <UserSwittch />,
  file: <File />,
  textToSpeech: <TextToSpeech />,
  recording: <Recording />,
  segments: <Segments />,
  play: <Play />,
  pause: <Pause />,
  warning: <Warning />,
  mailchimp: <Mailchimp />,
  typeform: <Typeform />,
  aWeber: <AWeber />,
  drip: <Drip />,
  unbounce: <Unbounce />,
  undo: <Undo />,
  gravityForms: <GravityForms />,
  stripe: <Stripe />,
  leadpages: <Leadpages />,
}

export type IIcon = keyof typeof icons

type IIconProps = {
  icon: IIcon
  fontSize?: CSSProperties['fontSize']
  color?: ColorsValues
  hoverColor?: ColorsValues
  className?: string
}

export const Icon = ({
  icon,
  fontSize = 16,
  color,
  hoverColor,
  className,
}: IIconProps) => {
  const [hover, setHover] = useState(false)
  const styleColor = hoverColor && hover ? hoverColor : color
  return (
    <span
      className={classNames(styles.wrap, 'app-icon', className)}
      style={{ fontSize, height: fontSize, color: styleColor }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {icons[icon]}
    </span>
  )
}
