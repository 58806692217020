import styles from './style.module.scss'
import { InputText } from 'components/Input/InputText/InputText'
import { Card } from 'components/Card/Card'
import { Tabs } from 'components/Tabs/Tabs'
import React, { useEffect } from 'react'
import { SizedBox } from 'components/SizedBox'
import { observer } from 'mobx-react-lite'
import { SMSContent } from 'components/CreateElement/SMSContent/SMSContent'
import { MainVoiceContent } from 'components/CreateElement/MainVoiceContent'
import { RinglessContent } from 'components/CreateElement/RinglessContent'
import { Switch } from 'components/Switch/Switch'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { mockApps } from 'store/triggers/mockData'
import mainStore from 'store/mainStore'
import { MessageTemplatesModal } from 'src/widgets/MessageTemplate'
import { useCreateTriggerContext } from 'src/routes/main/triggers/create/state/CreateTriggerState'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { Status } from 'src/generated/graphql'
import { ActionsContent } from 'src/routes/main/triggers/create/ActionContent/ActionsContent'
import { useGetTriggers } from 'src/routes/main/triggers/hooks/useGetTriggers'
import { useSetIntegrations } from 'src/routes/settings/integrations/hooks/useSetIntegrations'
import { useSetupIntegrations } from 'src/routes/settings/integrations/hooks/useSetupIntegrations'
import { useGetIntegrationsFields } from 'src/routes/main/triggers/hooks/useGetIntegrationsFields'
import { ConnectIntegrationBtn } from 'src/routes/settings/integrations/content/card/ConnectIntegrationBtn'
import uiStore from 'store/uiStore'
import classNames from 'classnames'
import { useElementName } from 'src/hooks/useElementName'
import { AddContactsToListDropdown } from 'src/Modals/ImportContactModal/StepsContent/Step3/AddContactsToListDropdown'
import { MergeTokenModal } from 'src/routes/main/contacts/customFields/modals/MergeTokenModal/MergeTokenModal'
import { CommonIntegrationsModal } from 'src/routes/settings/integrations/modal/connectIntegrationsModal/CommonIntegrationsModal'

export const NewTrigger = observer(() => {
  const createTriggerStore = useCreateTriggerContext()
  const { refetchFields } = useGetIntegrationsFields({
    byPassCache: true,
  })
  const { loading: loadingIntegrations } = useSetIntegrations()

  const { loading, accountIntegrationType } =
    useSetupIntegrations(refetchFields)

  useGetTriggers()

  useEffect(() => {
    mainStore.setSelectedTriggerApp(createTriggerStore.selectedApp?.value)
  }, [createTriggerStore.selectedApp])

  const { error, onBlur, onFocus } = useElementName(createTriggerStore)

  return (
    <Card
      width={600}
      noMobileRadius
      className={classNames(uiStore.isMobile && 'minHeight100')}
    >
      <MergeTokenModal />
      <CommonIntegrationsModal />
      <MessageTemplatesModal store={createTriggerStore} />
      <span className={'gray1'}>
        {`${createTriggerStore.edit ? 'Edit' : 'New'} trigger`}
      </span>
      <div className={styles.inputWrap}>
        <InputText
          id={'name'}
          placeholder={'Trigger name'}
          withoutBorder
          header
          // tabIndex={1}
          value={createTriggerStore.name}
          onChange={(event) => {
            createTriggerStore.setName(event.target.value)
          }}
          withoutClear
          error={error}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        <div className={styles.switch}>
          <span>
            {createTriggerStore.activeTrigger ? Status.Active : Status.Disabled}
          </span>
          <Switch
            active={createTriggerStore.activeTrigger}
            onChange={(value) => createTriggerStore.setActiveTrigger(value)}
            tabIndex={-1}
          />
        </div>
      </div>
      {(createTriggerStore.edit || createTriggerStore.duplicate) && (
        <>
          <SizedBox height={12} />
          <StatusContent status={createTriggerStore.status} />
        </>
      )}
      <SizedBox height={40} />
      <div className={styles.appDropdownContent}>
        <div className={styles.appDropdownWrap}>
          <Dropdown
            // tabIndex={2}
            labelInput={'App'}
            placeholder={'Select app'}
            options={mockApps}
            selectedOption={createTriggerStore.selectedApp || undefined}
            onSelect={(option) => createTriggerStore.setSelectedApp(option)}
            withIconRender
            className={styles.appDrop}
          />
          {createTriggerStore.showPhoneDropdown && (
            <Dropdown
              // tabIndex={3}
              labelInput={'Phone field'}
              options={createTriggerStore.phoneFieldOptions}
              selectedValue={createTriggerStore.appPhoneField}
              onSelectValue={(appPhoneField) =>
                createTriggerStore.setSelectedPhoneField(appPhoneField)
              }
              withIconRender
              className={styles.phoneDrop}
            />
          )}
        </div>

        {createTriggerStore.showConnectBtn && (
          <ConnectIntegrationBtn
            loadingIntegrationType={accountIntegrationType}
            type={createTriggerStore.selectedApp?.value}
            loading={loading || loadingIntegrations}
            authUrl={createTriggerStore.authUrl}
            text={'Connect your account'}
            refetchFields={refetchFields}
          />
        )}
      </div>
      <Tabs
        tabs={['SMS', 'Voice', 'Ringless']}
        activeTabIndex={createTriggerStore.activeTab}
        onTabClick={(index) => createTriggerStore.setActiveTab(index)}
      />
      <SizedBox height={16} />
      {createTriggerStore.activeTab === 0 && (
        <SMSContent store={createTriggerStore} />
      )}
      {createTriggerStore.activeTab === 1 && (
        <MainVoiceContent store={createTriggerStore} />
      )}
      {createTriggerStore.activeTab === 2 && (
        <RinglessContent store={createTriggerStore} />
      )}
      <SizedBox height={24} />
      <AddContactsToListDropdown
        store={createTriggerStore.dropdownContactListsStore}
      />
      <ActionsContent />
    </Card>
  )
})
