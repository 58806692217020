import React, { FC, useMemo } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as Checkbox } from 'icons/Checkbox.svg'
import { ReactComponent as CheckboxRound } from 'icons/Checkbox-Round.svg'
import { ReactComponent as CheckboxMinus } from 'icons/CheckboxMinus.svg'
import classNames from 'classnames'

type Props = {
  checked: boolean
  radio?: boolean
  disabled?: boolean
  onChecked?: React.MouseEventHandler<HTMLButtonElement>
  minus?: boolean
  round?: boolean
  size24?: boolean
}

export const CheckBox: FC<Props> = ({
  checked,
  radio,
  onChecked,
  disabled,
  minus,
  round,
  size24,
}) => {
  const isStandard = !radio && !round
  const content = useMemo(() => {
    if (minus) {
      return <CheckboxMinus />
    }
    if (checked) {
      if (radio) {
        return <div className={styles.radioChecked} />
      } else {
        return round ? <CheckboxRound /> : <Checkbox />
      }
    } else {
      return (
        <div
          className={classNames(
            styles.empty,
            (radio || round) && styles.radio,
            size24 && styles.size24
          )}
        />
      )
    }
  }, [minus, checked, radio])
  return (
    <button
      className={classNames(
        styles.wrap,
        isStandard && styles.standard,
        round && styles.round
      )}
      onClick={onChecked}
      disabled={disabled}
    >
      {content}
    </button>
  )
}
