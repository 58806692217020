import { useGetFullTollFreeVerificationProfileQuery } from 'src/generated/graphql'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import { useEffect } from 'react'

export function useSetFullTollFreeProfile(skip?: boolean) {
  const { data, loading, refetch } = useGetFullTollFreeVerificationProfileQuery(
    {
      skip,
    }
  )
  const { setProfile } = tollFreeVerificationStore
  useEffect(() => {
    if (data?.getFullTollFreeVerificationProfile) {
      setProfile(data?.getFullTollFreeVerificationProfile)
    }
  }, [data])

  return { loading, refetch }
}
