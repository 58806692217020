import { makeAutoObservable, reaction } from 'mobx'
import { IOption } from 'src/types/IOption'
import {
  Asset,
  ContactInfoRequestInput,
  Maybe,
  SecondStreetAddress,
  TfBusinessInfoRequestInput,
  TollFreeBusinessInfo,
  TollFreeContactInfo,
  TollFreeVerificationMessageAmount,
  TollFreeVerificationOptInMethod,
  TollFreeVerificationOptInMethodRequestInput,
  TollFreeVerificationProfile,
  TollFreeVerificationRequestInput,
  TollFreeVerificationStatus,
  TollFreeVerificationStep,
  TollFreeVerificationUseCaseRequestInput,
  TollFreeVerificationUseCaseType,
} from 'src/generated/graphql'
import { numberToOption } from 'store/settings/numbers/numberToOption'
import numbersStore from 'store/settings/numbers/numbersStore'
import { requiredDetector } from 'src/util/validators'
import typesStore from 'store/typesStore'

export const maxLengthUseCaseDesc = 500
export const maxLengthOptInDesc = 500
export const minLengthOptInDesc = 100
export const maxLengthSample = 1000

export enum OptInAgreementsEnum {
  isHaveComplianceOptIn = 'isHaveComplianceOptIn',
  isHaveTermsOptIn = 'isHaveTermsOptIn',
  isHavePrivacyOptIn = 'isHavePrivacyOptIn',
  isHaveFooterLinksOptIn = 'isHaveFooterLinksOptIn',
}

export const optInAgreementsMapByMethod: {
  [key in TollFreeVerificationOptInMethod]: OptInAgreementsEnum[]
} = {
  [TollFreeVerificationOptInMethod.IncomingText]: [
    OptInAgreementsEnum.isHaveComplianceOptIn,
    OptInAgreementsEnum.isHaveTermsOptIn,
    OptInAgreementsEnum.isHavePrivacyOptIn,
  ],
  [TollFreeVerificationOptInMethod.OnlineForm]: [
    OptInAgreementsEnum.isHaveComplianceOptIn,
    OptInAgreementsEnum.isHaveTermsOptIn,
    OptInAgreementsEnum.isHavePrivacyOptIn,
    OptInAgreementsEnum.isHaveFooterLinksOptIn,
  ],
  [TollFreeVerificationOptInMethod.PaperForm]: [
    OptInAgreementsEnum.isHaveComplianceOptIn,
  ],
  [TollFreeVerificationOptInMethod.QrCode]: [
    OptInAgreementsEnum.isHaveComplianceOptIn,
    OptInAgreementsEnum.isHaveTermsOptIn,
    OptInAgreementsEnum.isHavePrivacyOptIn,
  ],
}

export class TollFreeVerificationStore {
  constructor() {
    makeAutoObservable(this)

    reaction(
      () => this.optInMethod,
      () => {
        if (this.optInMethod) {
          this.optInAgreementsMap = new Map(
            optInAgreementsMapByMethod[this.optInMethod].map(
              (optInAgreement) => [optInAgreement, false]
            )
          )
        }
      }
    )
  }

  profile: TollFreeVerificationProfile | null = null
  modalProfile: TollFreeVerificationProfile | null = null
  adminOrgId: number | null = null
  openEditModal = false

  mapErrors: Map<string, boolean> = new Map()
  initLoaded = false

  currentStep = TollFreeVerificationStep.Numbers

  stepCount = Object.values(TollFreeVerificationStep).length

  businessInfo: TollFreeBusinessInfo | null = null
  contactInfo: TollFreeContactInfo | null = null
  useCaseName = ''
  explanation = ''
  messageAmount: Maybe<TollFreeVerificationMessageAmount> = null
  useCaseType: Maybe<TollFreeVerificationUseCaseType> = null
  useCaseDescription = ''
  optInMethod: TollFreeVerificationOptInMethod | null = null
  optInAsset: Asset | null = null
  sampleMessage = ''
  openModal = false
  selectedNumbersOptions: IOption[] = []
  status: TollFreeVerificationStatus | undefined = undefined

  isAgreeTerms = false

  optInAgreementsMap: Map<OptInAgreementsEnum, boolean> = new Map<
    OptInAgreementsEnum,
    boolean
  >()

  reset = () => {
    this.modalProfile = null
    this.initLoaded = false
    this.businessInfo = null
    this.contactInfo = null
    this.mapErrors.clear()

    this.currentStep = TollFreeVerificationStep.Numbers

    this.useCaseName = ''
    this.explanation = ''
    this.messageAmount = null
    this.useCaseType = null
    this.useCaseDescription = ''
    this.sampleMessage = ''
    this.openModal = false
    this.selectedNumbersOptions = []
    this.status = undefined

    this.optInMethod = null
    this.optInAsset = null

    this.optInAgreementsMap.clear()
    this.isAgreeTerms = false
  }

  get isSuperAdminModal() {
    return !!this.adminOrgId
  }

  get step() {
    switch (this.currentStep) {
      case TollFreeVerificationStep.Numbers:
        return 1
      case TollFreeVerificationStep.BusinessInfo:
        return 2
      case TollFreeVerificationStep.ContactInfo:
        return 3
      case TollFreeVerificationStep.UseCase:
        return 4
      case TollFreeVerificationStep.OptInMethod:
        return 5
      case TollFreeVerificationStep.Terms:
        return 6
    }
    return 1
  }

  get verificationErrorDescription() {
    return (this.isSuperAdminModal ? this.modalProfile : this.profile)
      ?.verificationErrorDescription
  }
  get verificationResponse() {
    return (this.isSuperAdminModal ? this.modalProfile : this.profile)
      ?.verificationResponse
  }
  get isNotResubmitable() {
    return (this.isSuperAdminModal ? this.modalProfile : this.profile)
      ?.isNotResubmitable
  }

  get completedStep() {
    return this.step - 1
  }

  get completedPercent() {
    return ((this.step - 1) / this.stepCount) * 100
  }

  get completed() {
    return this.status && this.status !== TollFreeVerificationStatus.Draft
  }

  get numberIds() {
    return this.selectedNumbersOptions.map(
      (opt) => numbersStore.numbersMapByFriendly.get(opt.value)?.id
    )
  }

  get tollFreeVerificationRequestInput(): TollFreeVerificationRequestInput {
    return {
      numberIds: this.numberIds,
      reasonOfMultipleNumbers: this.explanation || null,
    }
  }

  get tfBusinessInfoRequestInput(): TfBusinessInfoRequestInput {
    return {
      name: this.businessInfo?.name,
      website: this.businessInfo?.website,
      countryCode: this.businessInfo?.address?.country?.countryCode,
      street: this.businessInfo?.address?.street,
      secondStreet: this.businessInfo?.address?.secondStreet,
      city: this.businessInfo?.address?.city,
      state: this.businessInfo?.address?.state,
      zipCode: this.businessInfo?.address?.zipCode,
    }
  }

  get contactInfoRequestInput(): ContactInfoRequestInput {
    return {
      firstName: this.contactInfo?.firstName,
      lastName: this.contactInfo?.lastName,
      email: this.contactInfo?.email,
      phoneNumber: this.contactInfo?.formattedPhoneNumber,
    }
  }

  get tollFreeVerificationUseCaseRequestInput(): TollFreeVerificationUseCaseRequestInput {
    return {
      type: this.useCaseType,
      description: this.useCaseDescription,
      messageAmount: this.messageAmount,
      sampleMessage: this.sampleMessage,
    }
  }

  get tollFreeVerificationOptInMethodRequestInput(): TollFreeVerificationOptInMethodRequestInput {
    return {
      optInMethod: this.optInMethod,
      imageId: this.optInAsset?.id,
    }
  }

  get optInOptions(): IOption[] {
    return Object.values(TollFreeVerificationOptInMethod).map((method) => ({
      value: method,
      title: typesStore.tfOptInMethodMapping.get(method)?.title || method,
    }))
  }

  get useCaseTypesOptions() {
    const options: IOption[] = []
    typesStore.tfUseCaseMapping.forEach((title, value) => {
      options.push({
        title,
        value,
      })
    })
    return options
  }
  get messageAmountOptions() {
    const options: IOption<TollFreeVerificationMessageAmount>[] = []
    typesStore.tfMessageAmountMapping.forEach((title, value) => {
      options.push({
        title,
        value,
      })
    })
    return options
  }

  get disabledSelectedNumbers() {
    if (!this.selectedNumbersOptions.length) {
      return true
    }
    if (this.selectedNumbersOptions.length > 1) {
      return !this.explanation.trim()
    } else {
      return false
    }
  }

  get disabledBusinessInfo() {
    if (!this.businessInfo?.name?.trim()) {
      return true
    }
    if (!this.businessInfo?.website?.trim()) {
      return true
    }
    if (this.mapErrors.get('website')) {
      return true
    }
    if (this.businessInfo && this.businessInfo.address) {
      const addressKeys: Array<keyof SecondStreetAddress> = [
        'country',
        'street',
        'city',
        'state',
        'zipCode',
      ]
      return requiredDetector(this.businessInfo.address, addressKeys)
    }
    return false
  }

  get disabledContactInfo() {
    if (this.mapErrors.get('contactPhoneNumber')) {
      return true
    }
    if (this.mapErrors.get('contactEmail')) {
      return true
    }
    if (!this.contactInfo) {
      return true
    }
    if (this.contactInfo) {
      const keys: Array<keyof TollFreeContactInfo> = [
        'firstName',
        'lastName',
        'email',
        'formattedPhoneNumber',
      ]
      return requiredDetector(this.contactInfo, keys)
    }
    return false
  }

  get disabledUseCase() {
    return (
      !this.useCaseType ||
      !this.useCaseDescription.trim() ||
      this.useCaseDescription.length > maxLengthUseCaseDesc ||
      !this.sampleMessage.trim() ||
      this.sampleMessage.length > maxLengthSample ||
      !this.messageAmount
    )
  }

  get disabledUploadOptInImage() {
    return Array.from(this.optInAgreementsMap.values()).some((value) => !value)
  }

  get disabledOptInMethod() {
    if (!this.optInMethod) {
      return true
    }
    if (this.disabledUploadOptInImage) {
      return true
    }
    if (!this.optInAsset) {
      return true
    }
    return false
  }

  get continueText() {
    if (this.step === this.stepCount) {
      return 'Submit for review'
    }
    return 'Continue'
  }

  get stepTitle() {
    switch (this.currentStep) {
      case TollFreeVerificationStep.Numbers:
        return 'Numbers'
      case TollFreeVerificationStep.BusinessInfo:
        return 'Business info'
      case TollFreeVerificationStep.ContactInfo:
        return 'Contact info'
      case TollFreeVerificationStep.UseCase:
        return 'Use case'
      case TollFreeVerificationStep.OptInMethod:
        return 'Opt-in'
      case TollFreeVerificationStep.Terms:
        return 'Terms'
    }
    return ''
  }

  get progressPercent() {
    return (this.step / this.stepCount) * 100
  }

  get addressString() {
    const street = `${this.businessInfo?.address?.street || ''} ${
      this.businessInfo?.address?.secondStreet || ''
    }`.trim()
    return `${street}, ${this.businessInfo?.address?.city}, ${this.businessInfo?.address?.state} ${this.businessInfo?.address?.zipCode}, ${this.businessInfo?.address?.country?.countryName}`
  }

  setProfile = (profile: TollFreeVerificationProfile, fromModal?: boolean) => {
    if (!fromModal) {
      this.profile = profile
    } else {
      this.modalProfile = profile
    }

    this.initLoaded = true
    this.currentStep = profile.currentStep || TollFreeVerificationStep.Numbers
    this.businessInfo = profile.businessInfo || null
    this.contactInfo = profile.contactInfo || null
    this.messageAmount = profile.messageAmount || null
    this.useCaseName = profile.useCaseName || ''

    this.useCaseType = profile.useCase?.useCaseType || null
    this.useCaseDescription = profile.useCase?.useCaseDescription || ''
    this.optInMethod = profile.optInMethod || null
    this.optInAsset = profile.optInAsset || null

    this.sampleMessage = profile.firstSampleMessage || ''

    this.explanation = profile.reasonOfMultipleNumbers || ''
    this.selectedNumbersOptions =
      profile.phoneNumbers?.map((number) => numberToOption(number)) || []

    this.status = profile.status || undefined
  }

  getTitleByType = (type?: string | null) => {
    if (!type) {
      return ''
    }
    const founded = typesStore.tollFreeMapping.get(type)
    return founded || ''
  }

  setCompleted = () => {
    this.currentStep = TollFreeVerificationStep.Numbers
  }
  setMessageAmount = (value: TollFreeVerificationMessageAmount | null) => {
    this.messageAmount = value
  }
  setUseCaseType = (value: TollFreeVerificationUseCaseType) => {
    this.useCaseType = value
  }
  setUseCaseDescription = (value: string) => {
    this.useCaseDescription = value
  }

  setSampleMessage = (value: string) => {
    this.sampleMessage = value
  }

  setOpenModal = (
    state: boolean,
    modalProfile?: TollFreeVerificationProfile,
    organizationId?: number
  ) => {
    if (!state) {
      if (this.profile) {
        this.setProfile(this.profile)
      } else {
        this.reset()
      }
    }
    if (state && modalProfile) {
      this.adminOrgId = organizationId || null
      this.setProfile(modalProfile, true)
    }
    this.openModal = state
  }
  setSelectedNumbersOptions = (options: IOption[]) => {
    this.selectedNumbersOptions = options
  }
  onDeleteNumber = (id: string) => {
    this.selectedNumbersOptions = this.selectedNumbersOptions.filter(
      (option) => option.value !== id
    )
  }

  setBusinessInfo = (businessInfo: typeof this.businessInfo) => {
    this.businessInfo = businessInfo
  }
  setContactInfo = (contactInfo: typeof this.contactInfo) => {
    this.contactInfo = contactInfo
  }

  setExplanation = (explanation: string) => {
    this.explanation = explanation
  }
  setError = (key: string, value: boolean) => {
    this.mapErrors.set(key, value)
  }

  setOptInMethod = (optInMethod: typeof this.optInMethod) => {
    this.optInMethod = optInMethod
  }

  setOptInAsset = (value: typeof this.optInAsset) => {
    this.optInAsset = value
  }

  setOptInAgreement = (key: OptInAgreementsEnum, value: boolean) => {
    this.optInAgreementsMap.set(key, value)
  }

  setAgreeTerms = (value: boolean) => {
    this.isAgreeTerms = value
  }

  setOpenEditModal = (
    state: boolean,
    modalProfile?: TollFreeVerificationProfile,
    adminOrgId?: number
  ) => {
    if (!state) {
      if (this.profile) {
        this.setProfile(this.profile)
      } else {
        this.reset()
      }
    }
    if (state && modalProfile) {
      this.openModal = false
      this.adminOrgId = adminOrgId || null
      this.setProfile(modalProfile, true)
    }
    this.openEditModal = state
    if (!state) {
      this.adminOrgId = null
    }
  }
}

export default new TollFreeVerificationStore()
