import { Textarea } from 'components/Textarea/Textarea'
import React from 'react'
import styles from './style.module.scss'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { ReactComponent as Phone } from 'icons/16px/Phone.svg'
import { SizedBox } from 'components/SizedBox'
import { AddNumber } from 'components/CreateElement/AddNumber'
import { observer } from 'mobx-react-lite'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import { Separator } from 'components/Separator/Separator'
import { ColorsValues } from 'styles/variables'
import { CreateWebFormStore } from 'store/webForms/createWebFormStore'
import { AppIcon } from 'components/AppIcon/AppIcon'
import { CreateStoreTypes } from 'components/CreateElement/store/types'
import { appLinks } from 'src/util/links'
import { RoutesEnum } from 'src/routes/routes'
import { LinkWrapper } from 'components/Wrappers/LinkWrapper'
import numbersStore from 'store/settings/numbers/numbersStore'
import { IBottomDropdownButtonProps } from 'components/Dropdown/types'
import { CreateKeywordStore } from 'store/keywords/createKeywordStore'
import { useParams } from 'react-router-dom'
import { InputCollapsableCheckbox } from 'components/Input/InputCollapsableCheckbox/InputCollapsableCheckbox'
import { ComplianceContent } from 'components/CreateElement/SMSContent/ComplianceContent/ComplianceContent'

export const complianceMessageTT = (
  <>
    Append compliance language <br /> to your SMS text.{' '}
    <a
      className={'s1 medium'}
      href={appLinks.callLoopCompliance}
      target={'_blank'}
      onClick={(e) => e.stopPropagation()}
      rel="noreferrer"
    >
      Learn more.
    </a>{' '}
  </>
)

export const SMSContent = observer(
  ({
    store,
    noCompliance,
    noShortCode,
    noAutoFocus,
  }: {
    store: CreateStoreTypes
    noCompliance?: boolean
    noShortCode?: boolean
    noAutoFocus?: boolean
  }) => {
    const { id } = useParams()
    const onSelectNumber = (number: string) => {
      store.setSMSPhone(number)
    }

    const BottomDropdownButton = (props: IBottomDropdownButtonProps) => {
      return (
        <div className={'stickyBottom'}>
          {numbersStore.isSMSNumber && !numbersStore.isFirstNumber && (
            <Separator marginBlock={8} />
          )}
          <AddNumber
            {...props}
            setPhone={(phone) => store.setSMSPhone(phone)}
          />
        </div>
      )
    }

    return (
      <>
        <Textarea
          textareaStore={store.textareaStore}
          fromWebForm={store instanceof CreateWebFormStore}
          focusOnMount={
            !store.edit &&
            !(store instanceof CreateKeywordStore) &&
            !noAutoFocus &&
            !id
          }
          // tabIndex={2}
        />
        {!noCompliance && (
          <>
            <InputCollapsableCheckbox
              checked={store.textareaStore.compliance}
              onChecked={() => {
                store.textareaStore.setCompliance(
                  !store.textareaStore.compliance
                )
              }}
              collapsableContent={<ComplianceContent store={store} />}
              styleProps={{
                margin: '6px -12px',
              }}
            />
            <Separator color={ColorsValues.gray3} />
            {store instanceof CreateWebFormStore && (
              <>
                <InputCheckbox
                  label={'Double opt-in'}
                  checked={store.doubleOptIn}
                  onChecked={() => {
                    store.setDoubleOptIn(!store.doubleOptIn)
                  }}
                  tooltip={
                    'We recommend double opt-in to confirm the contact wishes to receive texts from your company.'
                  }
                  rowClassName={styles.inputCheckboxRow}
                  className={styles.inputCheckbox}
                />
                <Separator color={ColorsValues.gray3} />
              </>
            )}
          </>
        )}
        {store.textareaStore.errorMMSLength && (
          <span className={'s2 error1 mt12'}>
            Max 1600 characters for MMS. Shorten the message or breakdown it
            into multiple pieces.
          </span>
        )}
        {store.textareaStore.errorSMSLength && (
          <span className={'s2 error1 mt12'}>
            Max 10 segments for SMS. Shorten the message or breakdown it into
            multiple pieces.
          </span>
        )}
        {store.textareaStore.errorVerifyToolFree && (
          <span className={'s2 error1 mt12'}>
            Messages that include links, images (MMS) will be blocked by the
            carriers from unverified toll-free numbers. To avoid carrier
            filtering, please submit your company for{' '}
            <LinkWrapper to={RoutesEnum.settingsCompliance}>
              toll-free verification.
            </LinkWrapper>
          </span>
        )}
        <SizedBox height={40} />
        <div className={styles.sendWrap}>
          <Dropdown
            defaultOption={
              (numbersStore.smsPhoneNumbersVerificationsOptions.length === 1 &&
                numbersStore.smsPhoneNumbersVerificationsOptions[0]) ||
              undefined
            }
            selectedValue={store.smsPhone}
            onSelectValue={onSelectNumber}
            labelInput={'Send from'}
            options={
              noShortCode
                ? numbersStore.smsPhoneNumbersOptionsNoShortCode
                : numbersStore.smsPhoneNumbersOptions
            }
            BottomDropdownButton={BottomDropdownButton}
            leftIcon={
              <AppIcon>
                <Phone />
              </AppIcon>
            }
            dropDownContentWidth={450}
            grouping
          />
        </div>
      </>
    )
  }
)
