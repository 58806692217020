import styles from 'components/DetailsModalContent/styles.module.scss'
import { ClassesEnum } from 'styles/classes'
import parse from 'html-react-parser'
import { textToHtml } from 'src/util/functions'
import { COMPLIANCE_TEXT, REQUIRED_COMPLIANCE_TEXT } from 'src/static/constants'
import {
  RinglessMessageDetailsFragment,
  SmsMessageDetailsFragment,
  VoiceMessageDetailsFragment,
} from 'src/generated/graphql'
import { AudioCard } from 'components/CreateElement/AudioCard/AudioCard'
import { IAudioItem } from 'src/types/IAudioItem'

export const DetailTypeContent = ({
  voiceMessage,
  ringlessMessage,
  smsMessage,
}: {
  voiceMessage?: VoiceMessageDetailsFragment | null
  ringlessMessage?: RinglessMessageDetailsFragment | null
  smsMessage?: SmsMessageDetailsFragment | null
}) => {
  if (voiceMessage) {
    return (
      <div className={'col12'}>
        {voiceMessage?.liveAnswer?.liveAnswer && (
          <AudioCard
            audio={voiceMessage?.liveAnswer?.liveAnswer as IAudioItem}
            from={'DetailModalLiveAnswer'}
            rightTitleContent={'Live answer'}
            fullWidth
          />
        )}
        {voiceMessage?.voiceMail && (
          <AudioCard
            audio={voiceMessage?.voiceMail.voiceMail as IAudioItem}
            from={'DetailModalVoicemail'}
            rightTitleContent={'Voicemail'}
            fullWidth
          />
        )}
      </div>
    )
  }
  if (ringlessMessage) {
    if (ringlessMessage?.voiceMail) {
      return (
        <AudioCard
          audio={ringlessMessage?.voiceMail as IAudioItem}
          from={'DetailModalRingless'}
          rightTitleContent={'Ringless voicemail'}
          fullWidth
        />
      )
    }
  }
  if (smsMessage) {
    return (
      <div className={styles.info}>
        <p className={ClassesEnum.par2}>
          {parse(textToHtml(smsMessage?.text || '', true))}{' '}
          {smsMessage?.compliance
            ? smsMessage?.complianceText
              ? `${REQUIRED_COMPLIANCE_TEXT} ${smsMessage?.complianceText}`
              : COMPLIANCE_TEXT
            : ''}
        </p>
      </div>
    )
  }
  return null
}
