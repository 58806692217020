import billingStore from 'store/settings/billing/billingStore'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'

export const openBuyCreditsModal: typeof billingStore.setOpenBuyCreditsModal = (
  ...props
) => {
  if (props[0] && billingStore.isMigratedSubscription) {
    upgradePlanStore.setOpenUpgradePlanModal(true)
  } else {
    billingStore.setOpenBuyCreditsModal(...props)
  }
}
