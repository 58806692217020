import styles from './styles.module.scss'
import { CheckBox } from 'components/CheckBox/CheckBox'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import { Icon } from 'components/Icon/Icon'
import React, { CSSProperties, FC, ReactNode, useState } from 'react'
import { complianceMessageTT } from 'components/CreateElement/SMSContent/SMSContent'

export type InputCollapsableCheckbox = {
  checked: boolean
  onChecked?: (() => void) | undefined
  styleProps?: CSSProperties
  collapsableContent?: ReactNode
}

export const InputCollapsableCheckbox: FC<InputCollapsableCheckbox> = ({
  checked,
  styleProps,
  onChecked,
  collapsableContent,
}) => {
  const [isCollapse, setCollapse] = useState(true)
  const handleChecked: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    onChecked && onChecked()
  }
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={styles.wrap}
        style={styleProps}
        onClick={() => setCollapse(!isCollapse)}
      >
        <div className={styles.left}>
          <CheckBox checked={checked} onChecked={handleChecked} />
          <div className={'flex-center'}>
            <span>Compliance message</span>
            <InfoTooltip title={complianceMessageTT} />
          </div>
        </div>

        <Icon icon={!isCollapse ? 'angleUp' : 'angleDown'} />
      </div>
      {!isCollapse && collapsableContent}
    </>
  )
}
