// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p_DJmoH8zBddX3vleV8Q{margin:8px 0 40px 0}.WiS9hJvPS5_QEQhqk0sy{display:grid;grid-template-columns:262px 1fr;column-gap:12px;align-items:end}.c0e1y1JtP4Vmd1oUIrTO{display:flex;justify-content:space-between}.c0e1y1JtP4Vmd1oUIrTO .uhqlrkpuyLXmLtyunQFg{display:grid;grid-template-columns:150px 121px;column-gap:12px}.EuN_IAH9jPZbJlQ_9cpl{border:none;outline:none;margin:6px -12px;width:calc(100% + 24px)}.EuN_IAH9jPZbJlQ_9cpl:hover{outline:none;background-color:var(--primary-color-gray-4)}.GLZgsFuZ_wK7nNj66_1Q{display:flex;min-height:36px}.SLrGXnwYyzZKbSkrsvwg{display:flex;align-items:center}`, "",{"version":3,"sources":["webpack://./src/components/CreateElement/SMSContent/style.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CAEF,sBACE,YAAA,CACA,+BAAA,CACA,eAAA,CACA,eAAA,CAEF,sBACE,YAAA,CACA,6BAAA,CACA,4CACE,YAAA,CACA,iCAAA,CACA,eAAA,CAGJ,sBACE,WAAA,CACA,YAAA,CACA,gBAAA,CACA,uBAAA,CACA,4BACE,YAAA,CACA,4CAAA,CAGJ,sBACE,YAAA,CACA,eAAA,CAEF,sBACE,YAAA,CACA,kBAAA","sourcesContent":[".inputWrap{\n  margin: 8px 0 40px 0;\n}\n.sendWrap{\n  display: grid;\n  grid-template-columns: 262px 1fr;\n  column-gap: 12px;\n  align-items: end;\n}\n.actionsWrap{\n  display: flex;\n  justify-content: space-between;\n  .left{\n    display: grid;\n    grid-template-columns: 150px 121px;\n    column-gap: 12px;\n  }\n}\n.inputCheckbox{\n  border: none;\n  outline: none;\n  margin: 6px -12px;\n  width: calc(100% + 24px);\n  &:hover{\n    outline: none;\n    background-color: var(--primary-color-gray-4);\n  }\n}\n.inputCheckboxRow{\n  display: flex;\n  min-height: 36px;\n}\n.row{\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrap": `p_DJmoH8zBddX3vleV8Q`,
	"sendWrap": `WiS9hJvPS5_QEQhqk0sy`,
	"actionsWrap": `c0e1y1JtP4Vmd1oUIrTO`,
	"left": `uhqlrkpuyLXmLtyunQFg`,
	"inputCheckbox": `EuN_IAH9jPZbJlQ_9cpl`,
	"inputCheckboxRow": `GLZgsFuZ_wK7nNj66_1Q`,
	"row": `SLrGXnwYyzZKbSkrsvwg`
};
export default ___CSS_LOADER_EXPORT___;
